<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-uppercase">
            Kurum Adı
          </th>
          <th class="text-center text-uppercase">
            Kurum Açıklaması
          </th>
          <th class="text-center text-uppercase">
            Yetkili Adı Soyadı
          </th>
          <th class="text-center text-uppercase">
            Yetkili Telefon Numarası
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in kurumlar"
          :key="item.kurum_adi"
        >
          <td>{{ item.kurum_adi }}</td>
          <td class="text-center">
            {{ item.kurum_aciklama }}
          </td>
          <td class="text-center">
            {{ item.kurum_adi_soyadi }}
          </td>
          <td class="text-center">
            {{ item.kurum_telefon_numarasi }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>

import axios from 'axios'
 
export default {
  setup() {
    const kurumlar = [
      {
        kurum_adi: 'Frozen Yogurt',
        kurum_aciklama: 159,
        kurum_adi_soyadi: 6,
        kurum_telefon_numarasi: 24
      },
      {
        kurum_adi: 'Ice cream sandwich',
        kurum_aciklama: 237,
        kurum_adi_soyadi: 6,
        kurum_telefon_numarasi: 24
      },
      {
        kurum_adi: 'Eclair',
        kurum_aciklama: 262,
        kurum_adi_soyadi: 6,
        kurum_telefon_numarasi: 24
      },
      {
        kurum_adi: 'Cupcake',
        kurum_aciklama: 305,
        kurum_adi_soyadi: 6,
        kurum_telefon_numarasi: 24
      },
      {
        kurum_adi: 'Gingerbread',
        kurum_aciklama: 356,
        kurum_adi_soyadi: 6,
        kurum_telefon_numarasi: 24
      },
    ]
      axios.get('https://ziyaretapp-637e.restdb.io/rest/ziyaretci', {
        headers: {
        'cache-control': 'no-cache',
        'x-apikey': 'f7fd69da75b2033e01ce43e2e1fab67ec0d5e'
        }
      })
      .then(function (response) {
        kurumlar=response.data
      })

    return {
      kurumlar,
    }
  },
}
</script>
